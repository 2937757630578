import { useEffect, useState } from "react";
import Footer from "../Components/Layout/Footer/Footer";
import Header from "../Components/Layout/Header/Header";
import axios from "axios";
import $ from 'jquery'
import constant from "../Constants/constant";
import { Link } from "react-router-dom";
// alert('hello456')


function Home() {
   
    //    / $('.portfolio-area').mixItUp();
  
    const [user, setUser] = useState([])
    const [blog, setBlog] = useState([])
    const [project, setProject] = useState([])
    useEffect(() => {
        axios.get(constant.apiUrl + 'influencer/64f757d681cd3bb0f23b8bba')
            .then(res => {
                const persons = res.data;
                console.log(persons,'fbfdbbbbbbbb')
                setUser(persons);
                //  console.log(user, 'ddddd')
            })
    }, [])

    useEffect(() => {
        axios.get(constant.apiUrl + 'blog/64f757d681cd3bb0f23b8bba')
            .then(res => {
                const persons = res.data;
                console.log(res.data,'blog')
                // console.log(persons.data,'ddd')
                setBlog(persons);
                // console.log(blog, 'ddddd')
            })
         
          

    }, [])
    useEffect(() => {
        axios.get(constant.apiUrl + 'project/64f757d681cd3bb0f23b8bba')
        .then(res => {
            const persons = res.data;
            console.log(res.data,'project')
            // console.log(persons.data,'ddd')
            setProject(persons);
            // console.log(blog, 'ddddd')
        })    

    },[])

    return (
        <>
            {/* <div class="preloader">
        <div class="spinner">
            <div class="cube1"></div>
            <div class="cube2"></div>
        </div>
    </div> */}
            {/* <!-- // Preloader --> */}


            {/* <!-- ====== Header ====== --> */}
            <Header logo={user?.data?.logo}/>
            {/* <!-- ====== // Header ====== --> */}

            {/* <!-- ====== About Area ====== --> */}
            <section id="home" class="section-padding about-area bg-light">
                <div class="container">
                    {/* <!-- //Section Title --> */}
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="about-bg" style={{ backgroundImage: 'url(' + user?.data?.image + ')' }}>
                                {/* <!-- Social Link --> */}
                                <div class="social-aria">
                                    {user?.data?.socialLinks?.facebook &&
                                        <a target="_blank" href={user?.data?.socialLinks?.facebook}><i class="fab fa-facebook-f"></i></a>
                                    }
                                    {user?.data?.socialLinks?.twitter &&
                                        <a target="_blank" href={user?.data?.socialLinks?.twitter}><i class="fab fa-twitter"></i></a>

                                    }
                                    {user?.data?.socialLinks?.instagram &&
                                        <a target="_blank" href={user?.data?.socialLinks?.instagram}><i class="fab fa-instagram"></i></a>

                                    }
                                    {user?.data?.socialLinks?.pinterest &&
                                        <a target="_blank" href={user?.data?.socialLinks?.pinterest}><i class="fab fa-pinterest"></i></a>

                                    }
                                    {user?.data?.socialLinks?.youtube &&
                                        <a target="_blank" href={user?.data?.socialLinks?.youtube}><i class="fab fa-youtube"></i></a>

                                    }
                                </div>
                                {/* <!-- // Social Link --> */}
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-content">
                                <h2>Hello, I am <span>{user?.data?.influencername}</span></h2>
                                <h4>@{user?.data?.userName}</h4>
                                <p>{user?.data?.description}</p>

                                <h5>About Us</h5>

                                {/* <!-- Skill Area --> */}
                                <div id="skills" class="skill-area">

                                    {/* <!-- Single skill --> */}
                                    <div class="single-skill">
                                        <div class="skillbar" data-percent="100%">
                                            <div class="skillbar-title"><span>Followers</span></div>
                                            <div class="skillbar-bar"></div>
                                            <div class="skill-bar-percent">{user?.data?.followers}</div>
                                        </div>
                                    </div>
                                    {/* <!-- //Single skill --> */}

                                    {/* <!-- Single skill --> */}
                                    <div class="single-skill">
                                        <div class="skillbar" data-percent="85%">
                                            <div class="skillbar-title"><span>Engagement Rate %</span></div>
                                            <div class="skillbar-bar"></div>
                                            <div class="skill-bar-percent">{user?.data?.engagements}%</div>
                                        </div>
                                    </div>
                                    {/* <!-- //Single skill --> */}

                                    {/* <!-- Single skill --> */}
                                    <div class="single-skill">
                                        <div class="skillbar" data-percent="100%">
                                            <div class="skillbar-title"><span>Location</span></div>
                                            <div class="skillbar-bar"></div>
                                            <div class="skill-bar-percent">{user?.data?.location}</div>
                                        </div>
                                    </div>
                                    {/* <!-- //Single skill --> */}

                                </div>
                                {/* <!-- //Skill Area --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- ====== // About Area ====== -->

    <!-- ====== Featured Area ====== --> */}
            <section id="featured" class="section-padding pb-70" style={{ display: 'none' }}>
                <div class="container">
                    <div class="row">
                        {/* <!-- single featured item --> */}
                        <div class="col-lg-4 col-md-6">
                            <div class="single-featured-item-wrap">
                                <h3><a href="#">Graphic Design</a></h3>
                                <div class="single-featured-item">
                                    <div class="featured-icon">
                                        <i class="fa fa-edit"></i>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. In, voluptatibus, sunt. Recusandae ab aliquid voluptate exercitationem dicta ipsa, odio cumque sapiente quaerat nisi ad rem dolor iusto.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- single featured item -->
                <!-- single featured item --> */}
                        <div class="col-lg-4 col-md-6">
                            <div class="single-featured-item-wrap">
                                <h3><a href="#">Web Design</a></h3>
                                <div class="single-featured-item">
                                    <div class="featured-icon">
                                        <i class="fa fa-code"></i>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. In, voluptatibus, sunt. Recusandae ab aliquid voluptate exercitationem dicta ipsa, odio cumque sapiente quaerat nisi ad rem dolor iusto.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- single featured item -->
                <!-- single featured item --> */}
                        <div class="col-lg-4 col-md-6">
                            <div class="single-featured-item-wrap">
                                <h3><a href="#">SEO Services</a></h3>
                                <div class="single-featured-item">
                                    <div class="featured-icon">
                                        <i class="fa fa-search"></i>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. In, voluptatibus, sunt. Recusandae ab aliquid voluptate exercitationem dicta ipsa, odio cumque sapiente quaerat nisi ad rem dolor iusto.</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- single featured item --> */}
                    </div>
                </div>
            </section>
            {/* <!-- ====== //Featured Area ====== -->

    <!-- ====== Fact Counter Section ====== -->
    <!-- ====================================================================
            NOTE: You need to change  'data-count="10"' and 'p' Eliments 
        ===================================================================== --> */}
            <section class="section-padding pb-70 bg-img fact-counter" id="counter" style={{ backgroundImage: "url('/assets/images/fan-fact-bg.jpg')" }}>
                <div class="container">
                    <div class="row">
                        {/* <!-- Single Fact Counter --> */}
                        <div class="col-lg-3 co col-md-6 l-md-6 text-center">
                            <div class="single-fun-fact">
                                <h2><span class="counter-value" data-count={user?.data?.additional_info?.avgReelPlay}>{user?.data?.additional_info?.avgReelPlay}</span>+</h2>
                                <p>Average Reels Plays</p>
                            </div>
                        </div>
                        {/* <!-- // Single Fact Counter -->
                <!-- Single Fact Counter --> */}
                        <div class="col-lg-3 col-md-6 text-center">
                            <div class="single-fun-fact">
                                <h2><span class="counter-value" data-count={user?.data?.additional_info?.happyClients}>{user?.data?.additional_info?.happyClients}</span>+</h2>
                                <p>Happy Clients</p>
                            </div>
                        </div>
                        {/* <!-- // Single Fact Counter -->
                <!-- Single Fact Counter --> */}
                        <div class="col-lg-3 col-md-6 text-center">
                            <div class="single-fun-fact">
                                <h2><span class="counter-value" data-count={user?.data?.additional_info?.awardsWin}>{user?.data?.additional_info?.awardsWin}</span>+</h2>
                                <p>Awards Win</p>
                            </div>
                        </div>
                        {/* <!-- // Single Fact Counter -->
                <!-- Single Fact Counter --> */}
                        <div class="col-lg-3 col-md-6 text-center">
                            <div class="single-fun-fact">
                                <h2><span class="counter-value" data-count={user?.data?.additional_info?.cupofcoffee}>{user?.data?.additional_info?.cupofcoffee}</span>+</h2>
                                <p>Cups of Coffee</p>
                            </div>
                        </div>
                        {/* <!-- // Single Fact Counter --> */}
                    </div>
                </div>
            </section>
            {/* <!-- ====== //Fact Counter Section ====== -->

    <!-- ====== Service Section ====== --> */}
            <section id="" class="section-padding pb-70 service-area bg-light" style={{ display: 'none' }}>
                <div class="container">
                    {/* <!-- Section Title --> */}
                    <div class="row justify-content-center">
                        <div class="col-lg-6 ">
                            <div class="section-title text-center">
                                <h2>Service</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                        </div>
                    </div>
                    {/* <!-- //Section Title --> */}

                    <div class="row">
                        {/* <!-- Single Service --> */}
                        <div class="col-lg-4 col-md-6">
                            <div class="single-service">
                                <div class="service-icon">
                                    <i class="fa fa-code"></i>
                                </div>
                                <h2>Web Design</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente minima repudiandae amet, accusamus ea impedit aperiam consectetur libero. Deleniti id sit minima.</p>
                            </div>
                        </div>
                        {/* <!-- //Single Service -->
                <!-- Single Service --> */}
                        <div class="col-lg-4 col-md-6">
                            <div class="single-service">
                                <div class="service-icon">
                                    <i class="fa fa-chart-area"></i>
                                </div>
                                <h2>Business Services</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente minima repudiandae amet, accusamus ea impedit aperiam consectetur libero. Deleniti id sit minima.</p>
                            </div>
                        </div>
                        {/* <!-- //Single Service --> */}
                        {/* <!-- Single Service --> */}
                        <div class="col-lg-4 col-md-6">
                            <div class="single-service">
                                <div class="service-icon">
                                    <i class="fa fa-chart-line"></i>
                                </div>
                                <h2>Business Services</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente minima repudiandae amet, accusamus ea impedit aperiam consectetur libero. Deleniti id sit minima.</p>
                            </div>
                        </div>
                        {/* <!-- //Single Service --> */}
                    </div>

                </div>
            </section>
            {/* <!-- ====== //Service Section ====== --> */}

            {/* <!-- ====== Why choose Me Section ====== --> */}
            <section id="service" class="section-padding why-choose-us pb-70">
                <div class="container">
                    {/* <!-- Section Title --> */}
                    <div class="row justify-content-center">
                        <div class="col-lg-6 ">
                            <div class="section-title text-center">
                                <h2>Why choose Me</h2>
                                <p>Expect More. Pay Less.</p>
                            </div>
                        </div>
                    </div>
                    {/* <!-- //Section Title --> */}
                    <div class="row">
                        {/* <!-- Single Why choose me --> */}
                        <div class="col-md-6">
                            <div class="single-why-me why-me-left">
                                <div class="why-me-icon">
                                    <div class="d-table">
                                        <div class="d-table-cell">
                                            <i class="fa fa-clock"></i>
                                        </div>
                                    </div>
                                </div>
                                <h4>{user?.data?.why_choose_me[0]?.title}</h4>
                                <p>{user?.data?.why_choose_me[0]?.description}</p>
                            </div>
                        </div>
                        {/* <!-- // Single Why choose me --> */}

                        {/* <!-- Single Why choose me --> */}
                        <div class="col-md-6">
                            <div class="single-why-me why-me-right">
                                <div class="why-me-icon">
                                    <div class="d-table">
                                        <div class="d-table-cell">
                                            <i class="fa fa-calendar-check"></i>
                                        </div>
                                    </div>
                                </div>
                                <h4>{user?.data?.why_choose_me[1]?.title}</h4>
                                <p>{user?.data?.why_choose_me[1]?.description}</p>
                            </div>
                        </div>
                        {/* <!-- // Single Why choose me --> */}

                        {/* <!-- Single Why choose me --> */}
                        <div class="col-md-6">
                            <div class="single-why-me why-me-left">
                                <div class="why-me-icon">
                                    <div class="d-table">
                                        <div class="d-table-cell">
                                            <i class="fa fa-history"></i>
                                        </div>
                                    </div>
                                </div>
                                <h4>{user?.data?.why_choose_me[2]?.title}</h4>
                                <p>{user?.data?.why_choose_me[2]?.description}</p>
                            </div>
                        </div>
                        {/* <!-- // Single Why choose me --> */}

                        {/* <!-- Single Why choose me --> */}
                        <div class="col-md-6">
                            <div class="single-why-me why-me-right">
                                <div class="why-me-icon">
                                    <div class="d-table">
                                        <div class="d-table-cell">
                                            <i class="fa fa-phone-volume"></i>
                                        </div>
                                    </div>
                                </div>
                                <h4>{user?.data?.why_choose_me[3]?.title}</h4>
                                <p>{user?.data?.why_choose_me[3]?.description}</p>
                            </div>
                        </div>
                        {/* <!-- // Single Why choose me --> */}
                    </div>
                </div>
            </section>
            {/* <!-- ====== //Why choose Me Section ====== --> */}

            {/* <!-- ====== Portfolio Section ====== --> */}
            <section id="portfolio" class="section-padding pb-85 portfolio-area bg-light">
                <div class="container">
                    {/* <!-- Section Title --> */}
                    <div class="row justify-content-center">
                        <div class="col-lg-6 ">
                            <div class="section-title text-center">
                                <h2>Recent Work</h2>
                                <p>Laziness may appear attractive, but work gives satisfaction.</p>
                            </div>
                        </div>
                    </div>
                    {/* <!-- //Section Title --> */}
                    <div class="row justify-content-center">
                        {/* <!-- Work List Menu--> */}
                        <div class="col-lg-8">
                            <div class="work-list text-center">
                                <ul>
                                    <li class="filter active">ALL</li>
                                    <li class="filter" >Web Design</li>
                                    <li class="filter">Graphic</li>
                                    <li class="filter">Logo</li>
                                    <li class="filter">Wordpress</li>
                                    <li class="filter">Other</li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- // Work List Menu --> */}
                    </div>
                    <div class="row portfolio">
                    {/* {project?.data?.map((item,index)=>{
                        <div class="col-lg-4 col-md-6 mix wp graphic" key={index}>
                        <div class="single-portfolio" style={{ backgroundImage: "url('assets/images/portfolio/img-1.jpg')" }}>
                            <div class="portfolio-icon text-center">
                                <a data-lightbox='lightbox' href="assets/images/portfolio/img-1.jpg"><i class="fas fa-expand-arrows-alt"></i></a>
                            </div>
                            <div class="portfolio-hover">
                                <h4>{item.name}</h4>
                            </div>
                        </div>
                        </div>

                    })
                    } */}
                   <div class="col-lg-4 col-md-6 mix wp graphic">
                        <div class="single-portfolio" style={{ backgroundImage: "url('assets/images/portfolio/img-1.jpg')" }}>
                            <div class="portfolio-icon text-center">
                                <a data-lightbox='lightbox' href="assets/images/portfolio/img-1.jpg"><i class="fas fa-expand-arrows-alt"></i></a>
                            </div>
                            <div class="portfolio-hover">
                                <h4></h4>
                            </div>
                        </div>
                        </div>
                        {/* <!-- Single Portfolio --> */}
                       
                        {/* <!-- // Single Portfolio --> */}
                        {/* <!-- Single Portfolio --> */}
                           
                        {/* <!-- // Single Portfolio --> */}
                        {/* <!-- Single Portfolio --> */}
                        {/* <div class="col-lg-4 col-md-6 mix wp other">
                            <div class="single-portfolio" style={{ backgroundImage: "url('assets/images/portfolio/img-3.jpg')" }}>
                                <div class="portfolio-icon text-center">
                                    <a data-lightbox='lightbox' href="assets/images/portfolio/img-3.jpg"><i class="fas fa-expand-arrows-alt"></i></a>
                                </div>
                                <div class="portfolio-hover">
                                    <h4>Project <span>Name</span></h4>
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- // Single Portfolio --> */}
                        {/* <!-- Single Portfolio --> */}
                        {/* <div class="col-lg-4 col-md-6 mix logo other graphic wp web">
                            <div class="single-portfolio" style={{ backgroundImage: "url('assets/images/portfolio/img-4.jpg')" }}>
                                <div class="portfolio-icon text-center">
                                    <a data-lightbox='lightbox' href="assets/images/portfolio/img-4.jpg"><i class="fas fa-expand-arrows-alt"></i></a>
                                </div>
                                <div class="portfolio-hover">
                                    <h4>Project <span>Name</span></h4>
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- // Single Portfolio --> */}
                        {/* <!-- Single Portfolio --> */}
                        {/* <div class="col-lg-4 col-md-6 mix logo other wp graphic web">
                            <div class="single-portfolio" style={{ backgroundImage: "url('assets/images/portfolio/img-5.jpg')" }}>
                                <div class="portfolio-icon text-center">
                                    <a data-lightbox='lightbox' href="assets/images/portfolio/img-5.jpg"><i class="fas fa-expand-arrows-alt"></i></a>
                                </div>
                                <div class="portfolio-hover">
                                    <h4>Project <span>Name</span></h4>
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- // Single Portfolio --> */}
                        {/* <!-- Single Portfolio --> */}
                        {/* <div class="col-lg-4 col-md-6 mix wp logo graphic web">
                            <div class="single-portfolio" style={{ backgroundImage: "url('assets/images/portfolio/img-6.jpg')" }}>
                                <div class="portfolio-icon text-center">
                                    <a data-lightbox='lightbox' href="assets/images/portfolio/img-6.jpg"><i class="fas fa-expand-arrows-alt"></i></a>
                                </div>
                                <div class="portfolio-hover other">
                                    <h4>Project <span>Name</span></h4>
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- // Single Portfolio --> */}
                        {/* <!-- Single Portfolio --> */}
                        {/* <div class="col-lg-4 col-md-6 mix web wp">
                            <div class="single-portfolio" style={{ backgroundImage: "url('assets/images/portfolio/img-7.jpg')" }}>
                                <div class="portfolio-icon text-center">
                                    <a data-lightbox='lightbox' href="assets/images/portfolio/img-7.jpg"><i class="fas fa-expand-arrows-alt"></i></a>
                                </div>
                                <div class="portfolio-hover">
                                    <h4>Project <span>Name</span></h4>
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- // Single Portfolio --> */}
                        {/* <!-- Single Portfolio --> */}
                        {/* <div class="col-lg-4 col-md-6 mix logo graphic wp web">
                            <div class="single-portfolio" style={{ backgroundImage: "url('assets/images/portfolio/img-8.jpg')" }}>
                                <div class="portfolio-icon text-center">
                                    <a data-lightbox='lightbox' href="assets/images/portfolio/img-8.jpg"><i class="fas fa-expand-arrows-alt"></i></a>
                                </div>
                                <div class="portfolio-hover">
                                    <h4>Project <span>Name</span></h4>
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- // Single Portfolio -->
                <!-- Single Portfolio --> */}
                        {/* <div class="col-lg-4 col-md-6 mix other logo web">
                            <div class="single-portfolio" style={{ backgroundImage: "url('assets/images/portfolio/img-9.jpg')" }}>
                                <div class="portfolio-icon text-center">
                                    <a data-lightbox='lightbox' href="assets/images/portfolio/img-9.jpg"><i class="fas fa-expand-arrows-alt"></i></a>
                                </div>
                                <div class="portfolio-hover">
                                    <h4>Project <span>Name</span></h4>
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- // Single Portfolio --> */}
                    </div>
                </div>
            </section>
            {/* <!-- ====== // Portfolio Section ====== --> */}

            {/* <!-- ====== Frequently asked questions ====== --> */}
            <section class="section-padding faq-area bg-secondary" style={{ display: 'none' }}>
                <div class="container">
                    {/* <!-- Section Title --> */}
                    <div class="row justify-content-center">
                        <div class="col-lg-6 ">
                            <div class="section-title text-center faq-title">
                                <h2>Frequently asked questions</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                        </div>
                    </div>
                    {/* <!-- //Section Title --> */}
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="faq-bg bg-img" style={{ backgroundImage: "url('/assets/images/faq.jpeg')" }}></div>
                        </div>
                        <div class="col-lg-7">
                            {/* <!-- FAQ Content --> */}
                            <div class="faq-content" id="accordion">

                                {/* <!-- Single FAQ --> */}
                                <div class="single-faq">

                                    {/* <!-- FAQ Header --> */}
                                    <h4 class="collapsed" data-toggle="collapse" data-target="#faq-1">Collapsible Group Item One</h4>
                                    {/* <!-- // FAQ Header -->

                            <!-- FAQ Content --> */}
                                    <div id="faq-1" class="collapse show" data-parent="#accordion">
                                        <div class="faq-body">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat illo et, eum rerum nihil totam. Dolore atque quasi aspernatur fugiat commodi pariatur dignissimos, similique deleniti alias cumque, ea dolorum maiores, reprehenderit iusto quo officiis magni quibusdam est illum repellat adipisci quam qui error fugit? Fuga quam doloribus quas voluptas ducimus, adipisci minima quo consequatur ex!
                                        </div>
                                    </div>
                                    {/* <!-- FAQ Content --> */}
                                </div>
                                {/* <!-- // Single FAQ --> */}

                                {/* <!-- Single FAQ --> */}
                                <div class="single-faq">

                                    {/* <!-- FAQ Header --> */}
                                    <h4 class="collapsed" data-toggle="collapse" data-target="#faq-2">Lorem ipsum dolor sit amet Two</h4>
                                    {/* <!-- // FAQ Header --> */}

                                    {/* <!-- FAQ Content --> */}
                                    <div id="faq-2" class="collapse" data-parent="#accordion">
                                        <div class="faq-body">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat illo et, eum rerum nihil totam. Dolore atque quasi aspernatur fugiat commodi pariatur dignissimos, similique deleniti alias cumque, ea dolorum maiores, reprehenderit iusto quo officiis magni quibusdam est illum repellat adipisci quam qui error fugit? Fuga quam doloribus quas voluptas ducimus, adipisci minima quo consequatur ex!
                                        </div>
                                    </div>
                                    {/* <!-- FAQ Content --> */}
                                </div>
                                {/* <!-- // Single FAQ --> */}

                                {/* <!-- Single FAQ --> */}
                                <div class="single-faq">

                                    {/* <!-- FAQ Header --> */}
                                    <h4 class="collapsed" data-toggle="collapse" data-target="#faq-3">Collapsible Group Item Three</h4>
                                    {/* <!-- // FAQ Header -->

                            <!-- FAQ Content --> */}
                                    <div id="faq-3" class="collapse" data-parent="#accordion">
                                        <div class="faq-body">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat illo et, eum rerum nihil totam. Dolore atque quasi aspernatur fugiat commodi pariatur dignissimos, similique deleniti alias cumque, ea dolorum maiores, reprehenderit iusto quo officiis magni quibusdam est illum repellat adipisci quam qui error fugit? Fuga quam doloribus quas voluptas ducimus, adipisci minima quo consequatur ex!
                                        </div>
                                    </div>
                                    {/* <!-- FAQ Content --> */}
                                </div>
                                {/* <!-- // Single FAQ --> */}

                            </div>
                            {/* <!-- FAQ Content --> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- ====== // Frequently asked questions ====== --> */}

            {/* <!-- ====== Call to Action Area ====== --> */}
            <section class="section-padding call-to-action-aria bg-secondary" id="contact">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-9">
                            <h2>Get in touch with us</h2>
                            <p>Didn't find what you need? Speak us to directly, get in touch.</p>
                        </div>
                        <div class="col-lg-3">
                            <div class="cta-button">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="#" class="button">Contact me</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- ====== // Call to Action Area ====== -->

    <!-- ====== Blog Section ====== --> */}
            <section class="section-padding pb-70 blog-section bg-light" id="blog">
                <div class="container">
                    {/* <!-- Section Title --> */}
                    <div class="row justify-content-center">
                        <div class="col-lg-6 ">
                            <div class="section-title text-center">
                                <h2>Blog Area</h2>
                                <p>Blogging the reel world</p>
                            </div>
                        </div>
                    </div>
                    {/* <!-- //Section Title --> */}
                    <div class="row">
                        {/* <!-- Single Blog --> */}
                        {blog?.data?.map((item,index)=>{
                              if (index >= 3) {
                                return;
                            }
                       return <div class="col-lg-4 col-md-6">
                        <div class="single-blog">
                            <div class="blog-thumb" style={{ backgroundImage:  'url(' + item?.image + ')' }}></div>
                            <h4 class="blog-title"><a href="single-blog.html">{item.title}</a></h4>
                            <p class="blog-meta"><a href="#">{item?.influencerId?.influencername}</a>, {item.createdAt}</p>
                            <p>{item.description}</p>
                            <Link to="/blogdetail" class="button">Read More</Link>
                        </div>
                        </div>
                        })}
                       
                    </div>
                </div>
            </section>
            {/* <!-- ====== // Blog Section ====== -->


    <!-- ====== Testimonial Section ====== --> */}
            <section id="testimonial" class="section-padding bg-secondary testimonial-area" style={{ display: 'none' }}>
                <div class="container bg-white">
                    {/* <!-- Section Title --> */}
                    <div class="row justify-content-center">
                        <div class="col-lg-6 ">
                            <div class="section-title text-center">
                                <h2>Testimonial</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                        </div>
                        {/* </div>
            <!-- //Section Title --> */}
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="testimonials owl-carousel" data-ride="carousel">
                                    {/* <!-- Single Testimonial --> */}
                                    <div class="single-testimonial text-center">
                                        <div class="testimonial-quote">
                                            <i class="fa fa-quote-right"></i>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam nam suscipit similique quod eaque adipisci modi recusandae nesciunt veniam, ut, rem eligendi minima et, accusantium?</p>
                                        <h4>Aseven M <span>CEO - aseven.info</span></h4>

                                    </div>
                                    {/* <!-- // Single Testimonial -->
                        <!-- Single Testimonial --> */}
                                    <div class="single-testimonial text-center">
                                        <div class="testimonial-quote">
                                            <i class="fa fa-quote-right"></i>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam nam suscipit similique quod eaque adipisci modi recusandae nesciunt veniam, ut, rem eligendi minima et, accusantium?</p>
                                        <h4>Aseven M <span>CEO - aseven.info</span></h4>

                                    </div>
                                    {/* <!-- // Single Testimonial -->
                        <!-- Single Testimonial --> */}
                                    <div class="single-testimonial text-center">
                                        <div class="testimonial-quote">
                                            <i class="fa fa-quote-right"></i>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam nam suscipit similique quod eaque adipisci modi recusandae nesciunt veniam, ut, rem eligendi minima et, accusantium?</p>
                                        <h4>Aseven M <span>CEO - aseven.info</span></h4>

                                    </div>
                                    {/* <!-- // Single Testimonial --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- ====== // Testimonial Section ====== --> */}

            {/* <!-- ====== Team Section ====== --> */}
            <section class="section-padding pb-70 team-area" style={{ display: 'none' }}>
                <div class="container">
                    {/* <!-- Section Title --> */}
                    <div class="row justify-content-center">
                        <div class="col-lg-6 ">
                            <div class="section-title text-center">
                                <h2>Team</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                        </div>
                    </div>
                    {/* <!-- //Section Title --> */}
                    <div class="row">
                        {/* <!-- Single Team --> */}
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <div class="single-team">
                                <div class="team-thumb" style={{ backgroundImage: "url('/assets/images/team/img-1.jpg')" }}>
                                    <div class="team-social">
                                        <a target="_blank" href="#"><i class="fab fa-facebook-f"></i></a>
                                        <a target="_blank" href="#"><i class="fab fa-twitter"></i></a>
                                        <a target="_blank" href="#"><i class="fab fa-linkedin"></i></a>
                                        <a target="_blank" href="#"><i class="fab fa-pinterest"></i></a>
                                    </div>
                                </div>
                                <div class="team-content">
                                    <h4>AL Mamun</h4>
                                    <span>CEO Founder</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- // Single Team -->
                <!-- Single Team --> */}
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <div class="single-team">
                                <div class="team-thumb" style={{ backgroundImage: "url('/assets/images/team/img-2.jpg')" }}>
                                    <div class="team-social">
                                        <a target="_blank" href="#"><i class="fab fa-facebook-f"></i></a>
                                        <a target="_blank" href="#"><i class="fab fa-twitter"></i></a>
                                        <a target="_blank" href="#"><i class="fab fa-linkedin"></i></a>
                                        <a target="_blank" href="#"><i class="fab fa-pinterest"></i></a>
                                    </div>
                                </div>
                                <div class="team-content">
                                    <h4>AL Mamun</h4>
                                    <span>CEO Founder</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- // Single Team -->
                <!-- Single Team --> */}
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <div class="single-team">
                                <div class="team-thumb" style={{ backgroundImage: "url('assets/images/team/img-3.jpg')" }}>
                                    <div class="team-social">
                                        <a target="_blank" href="#"><i class="fab fa-facebook-f"></i></a>
                                        <a target="_blank" href="#"><i class="fab fa-twitter"></i></a>
                                        <a target="_blank" href="#"><i class="fab fa-linkedin"></i></a>
                                        <a target="_blank" href="#"><i class="fab fa-pinterest"></i></a>
                                    </div>
                                </div>
                                <div class="team-content">
                                    <h4>AL Mamun</h4>
                                    <span>CEO Founder</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- // Single Team -->
                <!-- Single Team --> */}
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <div class="single-team">
                                <div class="team-thumb" style={{ backgroundImage: "url('assets/images/team/img-4.jpg')" }}>
                                    <div class="team-social">
                                        <a target="_blank" href="#"><i class="fab fa-facebook-f"></i></a>
                                        <a target="_blank" href="#"><i class="fab fa-twitter"></i></a>
                                        <a target="_blank" href="#"><i class="fab fa-linkedin"></i></a>
                                        <a target="_blank" href="#"><i class="fab fa-pinterest"></i></a>
                                    </div>
                                </div>
                                <div class="team-content">
                                    <h4>AL Mamun</h4>
                                    <span>CEO Founder</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- // Single Team --> */}
                    </div>
                </div>
            </section>
            {/* <!-- ====== // Team Section ====== --> */}





            {/* <!-- ====== Pricing Area ====== --> */}
            <section class="section-padding pb-70 pricing-area" style={{ display: 'none' }}>
                <div class="container">
                    {/* <!-- Section Title --> */}
                    <div class="row justify-content-center">
                        <div class="col-lg-6 ">
                            <div class="section-title text-center">
                                <h2>Pricing Area</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                        </div>
                    </div>
                    {/* <!-- //Section Title --> */}
                    <div class="row">
                        {/* <!-- Single Price Box --> */}
                        <div class="col-lg-3 col-md-6">
                            <div class="single-price-box text-center">
                                <div class="price-head">
                                    <h2>Basic</h2>
                                    <h3>$99<span>/ Per Month</span></h3>
                                </div>
                                <ul>
                                    <li>Basic Feature</li>
                                    <li>Best Output</li>
                                    <li>Free Update</li>
                                    <li>Live chat</li>
                                </ul>
                                <a href="#" class="button">PURCHASE NOW</a>
                            </div>
                        </div>
                        {/* <!-- // Single Price Box -->
                <!-- Single Price Box --> */}
                        <div class="col-lg-3 col-md-6">
                            <div class="single-price-box text-center">
                                <div class="price-head">
                                    <h2>Premium</h2>
                                    <h3>$119<span>/ Per Month</span></h3>
                                </div>
                                <ul>
                                    <li>Basic Feature</li>
                                    <li>Best Output</li>
                                    <li>Free Update</li>
                                    <li>Live chat</li>
                                </ul>
                                <a href="#" class="button">PURCHASE NOW</a>
                            </div>
                        </div>
                        {/* <!-- // Single Price Box -->
                <!-- Single Price Box --> */}
                        <div class="col-lg-3 col-md-6">
                            <div class="single-price-box text-center">
                                <div class="price-head">
                                    <h2>Enterprise</h2>
                                    <h3>$559<span>/ Per Month</span></h3>
                                </div>
                                <ul>
                                    <li>Basic Feature</li>
                                    <li>Best Output</li>
                                    <li>Free Update</li>
                                    <li>Live chat</li>
                                </ul>
                                <a href="#" class="button">PURCHASE NOW</a>
                            </div>
                        </div>
                        {/* <!-- // Single Price Box -->
                <!-- Single Price Box --> */}
                        <div class="col-lg-3 col-md-6">
                            <div class="single-price-box text-center">
                                <div class="price-head">
                                    <h2>Business</h2>
                                    <h3>$999<span>/ Per Month</span></h3>
                                </div>
                                <ul>
                                    <li>Basic Feature</li>
                                    <li>Best Output</li>
                                    <li>Free Update</li>
                                    <li>Live chat</li>
                                </ul>
                                <a href="#" class="button">PURCHASE NOW</a>
                            </div>
                        </div>
                        {/* <!-- // Single Price Box --> */}
                    </div>
                </div>
            </section>

            <section id="contact" class="section-padding contact-section bg-light" style={{ display: 'none' }}>
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-lg-6 ">
                            <div class="section-title text-center">
                                <h2>Contact Me</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                        </div>
                    </div>



                    <div class="row justify-content-center">
                        <div class="col-lg-10">

                            <form id="contact-form" action="mail.php" method="post" class="contact-form bg-white">
                                <div class="row">
                                    <div class="col-lg-6 form-group">
                                        <input type="text" class="form-control" name="name" required placeholder="Name" />
                                    </div>
                                    <div class="col-lg-6 form-group">
                                        <input type="email" class="form-control" name="email" required placeholder="Email" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" name="subject" required placeholder="Subject" />
                                </div>

                                <div class="form-group">
                                    <textarea name="message" id="" class="form-control" required placeholder="Message"></textarea>
                                </div>
                                <div class="form-btn text-center">
                                    <button class="button" type="submit">Send Message</button>
                                    <p class="form-message"></p>
                                </div>
                            </form>

                        </div>
                    </div>
                    

                </div>
            </section>
            <section class="btn-section d-flex justify-content-center pb-5">
                <Link to="/blog" class="read-btn">Read More</Link>
            </section>

            <Footer />


        </>
    );
   
    
}

export default Home;