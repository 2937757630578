import { useEffect, useState } from "react";
import Footer from "../Components/Layout/Footer/Footer";
import Header from "../Components/Layout/Header/Header";
import constant from "../Constants/constant";
import moment from "moment/moment";
import axios from "axios";
import { Link } from "react-router-dom";

function Blogmain() {
    const [blog, setBlog] = useState([])

    useEffect(() => {
        axios.get(constant.apiUrl + 'blog/64f757d681cd3bb0f23b8bba')
            .then(res => {
                const persons = res.data;

                // console.log(persons.data,'ddd')
                setBlog(persons);
                console.log(blog.data, 'blog')
                // console.log(blog, 'ddddd')
            })



    }, [])

    return (
        <>
            <Header />
            <section id="home" class="section-padding2 about-area bg-light">
                <div class="container-fluid">
                    {/* <!-- //Section Title --> */}
                    <div class="row">
                        <div class="col-lg-12 p-0">
                            <div class="about-bg" style={{ backgroundImage: "url('/assets/images/background.jpg')" }}>
                                <h2 class="about-bg-h2">What your Blog Preference Says about You and Yours Personality.</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container mt-5">

                    <div class="row">
                        {blog?.data?.map((item) => (

                            <div class="col-lg-4">
                                <img src="/assets/images/img1.jpg" class="rounded" />
                                <div>
                                    <span class="span-tag mt-2">{moment(item.createdAt).format('DD MMM YYYY')}</span>
                                    <h5 class="h5-tag">{item?.title}</h5>
                                    <p class="p-tag">{item.description}<Link to={'/blogdetail/'+item._id} class="span2">Read More</Link> </p>
                                </div>
                            </div>


                        ))}
                    </div>


                </div>
            </section>
            <Footer />
        </>
    );


}

export default Blogmain;