import { useEffect, useState } from "react";
import Footer from "../Components/Layout/Footer/Footer";
import Header from "../Components/Layout/Header/Header";
import { Route, useParams } from "react-router-dom";
import axios from "axios";
import constant from "../Constants/constant";

function Blogdetail() {
    const [blog, setBlog] = useState([])
    const { id } = useParams();
    useEffect(() => {
        axios.get(constant.apiUrl + `blog/getbyblog/${id}`)
            .then(res => {
                const persons = res.data;

                // console.log(persons.data,'ddd')
                setBlog(persons);
                console.log(blog.data, 'blog')
                // console.log(blog, 'ddddd')
            })



    }, [])

    return (
        <> 
        <Header />        
        <section id="home" class="section-padding2 about-area bg-light">
            <div class="container-fluid">
                {/* <!-- //Section Title --> */}
                <div class="row">
                        <div class="col-lg-12 p-0">
                            <div class="about-bg" style={{ backgroundImage: "url('/assets/images/background.jpg')" }}>
                            <h2 class="about-bg-h2">What your Blog Preference Says about You and Yours Personality.</h2>
                            </div>
                        </div>
                        {/* <div class="col-lg-6">
                            <div class="about-content">
                                <p class="p1">About Us</p>
                                    <h3 class="h3">Give a helping hand to a needy people</h3>
                                    <span class="span-para">Lorem ipsum dolor sit amet, consectetur adipisicing elit, Lorem ipsum dolor sit amet, consectetur adipisicing elit, Lorem ipsum dolor sit amet, consectetur adipisicing elit, Lorem ipsum dolor sit amet, consectetur adipisicing elit , Lorem ipsum dolor sit amet, consectetur adipisicing elit, Lorem ipsum dolor sit amet, consectetur adipisicing elit, Lorem ipsum dolor sit amet, consectetur adipisicing elit, Lorem ipsum dolor sit amet, consectetur adipisicing elit , Lorem ipsum dolor sit amet, consectetur adipisicing elit, Lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                            </div>
                        </div> */}
                    </div>
            </div>
            <div class="container-fluid">
                <div class="d-flex justify-content-center">
             <div class="section-1">
            <div class="p-3">
            <img src={blog?.data?.image} class="img rounded" />
            </div>
            <div class="p-3">
                <h3>{blog?.data?.title}</h3>
            <p class="p-tag">{blog?.data?.description}</p>
            <h5 class="h5-tag"> 1. Amazing Stories from around Globe ?</h5>
            <p class="p-tag">Lorem ipsum, in graphical and textual context, refers to filler text that is placed ,Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur adipisicing elit. adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur.</p>
            <h5 class="h5-tag"> 2. Why Choose Vidhema Technologies for Magento Development Services</h5>
            <p class="p-tag">Lorem ipsum, in graphical and textual context, refers to filler text that is placed ,Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur adipisicing elit. adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur.</p>
             <ul>
                <li> <p class="p-tag">Lorem ipsum, in graphical and textual context, refers to filler text that is placed ,Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur adipisicing elit. adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur.</p></li>
                <li> <p class="p-tag">Lorem ipsum, in graphical and textual context, refers to filler text that is placed ,Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur adipisicing elit. adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur.</p></li>
             </ul>
             <h5 class="h5-tag"> 3. Why Choose Vidhema Technologies for Magento Development Services</h5>
             <p class="p-tag">Lorem ipsum, in graphical and textual context, refers to filler text that is placed ,Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur adipisicing elit. adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur.</p>
             <p class="p-tag">Lorem ipsum, in graphical and textual context, refers to filler text that is placed ,Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur adipisicing elit. adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur.</p>
             <h5 class="h5-tag"> 4. Scalability For Future Growth</h5>
             <p class="p-tag">In the realm of online shopping, user experience is king. It is a priority for Vidhema Technologies to design intuitive and user-friendly online storefronts. They make sure that every element of the store enhances the user experience, from intuitive navigation to responsive design.</p>
             </div>
             </div>
             </div>
            </div>

            {/* <div class="container-fluid mt-5">
                <div class="row p-4">
                    <div class="col-lg-4">
                 <div>
                 <img src="/assets/images/img2.webp" class="img rounded"/>
                 </div>
                    <div>
                       <div>
                       <span class="span-tag mt-2">September 2, 2023</span>
                       
                       </div>
                        <h5 class="h5-tag">Amazing Stories from around Globe ?</h5>
                        <p class="p-tag">Lorem ipsum, in graphical and textual context, refers to filler text that is placed ,Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nostrum nihil est saepe officia? Odit ad, magni nostrum expedita magnam exercitationem dicta quis, asperiores perspiciatis fuga consequuntur quasi, eaque iste , adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nostrum nihil est saepe officia? Odit ad, magni nostrum expedita magnam exercitationem dicta quis, asperiores perspiciatis fuga consequuntur quasi, eaque iste.</p>
                    </div>
                    </div>
                    <div class="col-lg-4">
                    <div>
                    <img src="/assets/images/img3.webp" class="img rounded"/>
                    </div>
                    <div>
                        <span class="span-tag mt-2">September 2, 2023</span>
                        <h5 class="h5-tag">Travelling tips from experts ?</h5>
                        <p class="p-tag">Lorem ipsum, in graphical and textual context, refers to filler text that is placed , adipisicing elit. Nisi nostrum nihil est saepe officia? Odit ad, magni nostrum expedita magnam exercitationem dicta quis, asperiores perspiciatis fuga consequuntur quasi, eaque iste , adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nostrum nihil est saepe officia? Odit ad, magni nostrum expedita magnam exercitationem dicta quis, asperiores perspiciatis fuga consequuntur quasi.</p>
                    </div>
                    </div>
                    <div class="col-lg-4">
                    <div>
                    <img src="/assets/images/img4.webp" class="img rounded"/>
                    </div>
                    <div>
                        <span class="span-tag mt-2">September 2, 2023</span>
                        <h5 class="h5-tag">The cutest things kids Everyday ?</h5>
                        <p class="p-tag">Lorem ipsum, in graphical and textual context, refers to filler text that is placed , to filler text that is placed ,Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nostrum nihil est saepe officia? Odit ad, magni nostrum expedita magnam exercitationem dicta quis, asperiores perspiciatis fuga consequuntur quasi, eaque iste , adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nostrum nihil est saepe officia , adipisicing elit. Perferendis quae maiores fugit omnis, Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nostrum nihil est saepe.</p>
                    </div>
                    </div>
                </div>
            </div> */}
        </section>
        <Footer />
        </>
    );
   
    
}

export default Blogdetail;